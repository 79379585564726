import { create } from 'zustand';
import {
  AccountInvitationDto,
  AllowedAccountListItem,
  OptionsResponseDto,
  UserDataDto,
} from '@just-ai/api/dist/generated/Accountsadmin';
import { UserApi, UserResponse } from '@just-ai/api/dist/generated/DataFilter';
import AccountsadminService from '@just-ai/api/dist/services/AccountsadminService';
import { AppLogger } from '@just-ai/logger';

import { axios } from 'utils/network/axios';
import { ccLogout, redirectToSelectAccount } from 'utils/redirect';

const userApi = new UserApi({}, '', axios);
const accountsadminService = new AccountsadminService(axios);

export type CurrentUserState = {
  ccOptions?: OptionsResponseDto;
  user?: UserResponse;
  ccUserData?: UserDataDto;
  lang: string;
  ccUserAllowedAccounts?: AllowedAccountListItem[];
  invitations?: AccountInvitationDto[];
  setLanguage: (lang: string) => void;
  loadCurrentUser: () => void;
  isUserLoading: boolean;
  logout: () => Promise<void>;
  getCurrentAccount: () => Promise<void>;
  getCloudOptions: () => Promise<void>;
  ccUserLoading: boolean;
};

export const useCurrentUser = create<CurrentUserState>((set, get) => ({
  ccOptions: undefined,
  user: undefined,
  ccUserData: undefined,
  lang: 'eng',
  isUserLoading: true,
  ccUserLoading: true,
  async loadCurrentUser() {
    const res = await userApi.currentUser();
    return set({ user: res.data, isUserLoading: false });
  },
  setLanguage(lang: string) {
    return set({ lang });
  },
  async logout() {
    ccLogout();
  },
  async getCloudOptions() {
    const options = await accountsadminService.getOptions().catch(AppLogger.createNetworkHandler('getCloudOptions'));
    set({ ccOptions: options || undefined });
    return get().getCurrentAccount();
  },
  async getCurrentAccount() {
    const state = get();

    if (!state.ccOptions?.domains) return;
    const product = Object.values(state.ccOptions?.domains).find(
      domain => domain.domain === window.location.hostname
    )?.product;

    const ccUserData = await accountsadminService
      .checkIsUserAuthorized()
      .catch(AppLogger.createNetworkHandler('getCurrentAccount.checkIsUserAuthorized'));

    if (!ccUserData?.userData?.accountId || ccUserData?.userData?.internal) {
      redirectToSelectAccount();
    }
    if (!ccUserData?.userData?.userId) return;

    const accounts = await accountsadminService
      .getAllowedAccounts(ccUserData.userData.userId, product)
      .catch(AppLogger.createNetworkHandler('getCurrentAccount.getAllowedAccounts'));

    const currentAccountFromAllowedAccounts = accounts?.find(account => account.id === ccUserData.userData.accountId);

    if (
      !accounts?.filter(account => account.availableToSelect).length ||
      !currentAccountFromAllowedAccounts?.availableToSelect
    ) {
      redirectToSelectAccount();
    }
    const invitations = await accountsadminService
      .getInvitationsByUser(ccUserData.userData.userId)
      .catch(AppLogger.createNetworkHandler('getCurrentAccount.getInvitationsByUser'));

    return set({
      ccUserAllowedAccounts: accounts || [],
      invitations: invitations || [],
      ccUserData,
      ccUserLoading: false,
    });
  },
}));
