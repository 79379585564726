export const errorsLocalization = {
  'dataguard.common.common_error': {
    eng: 'An internal error has occurred. Please try again or contact our support team.',
    ru: 'Произошла внутренняя ошибка. Пожалуйста, попробуйте еще раз или обратитесь в поддержку.',
  },
  'dataguard.common.not_unique_data': {
    eng: 'A key with this name already exists. Please specify a different name.',
    ru: 'Ключ с таким названием уже существует. Укажите другое название.',
  },
  'dataguard.common.not_unique_dictionary_name': {
    eng: 'A dictionary file with this name already exists. Please upload a different file.',
    ru: 'Файл словаря с таким названием уже существует. Загрузите другой файл.',
  },
  'dataguard.common.entity_key_is_not_unique': {
    eng: 'An entity with this name already exists. Please specify a different name.',
    ru: 'Сущность с таким названием уже существует. Укажите другое название.',
  },
  'dataguard.common.filter_is_used': {
    eng: 'The current filter is used in one of the API keys',
    ru: 'Фильтр используется в одном из API-ключей',
  },
  fileSizeError: {
    eng: 'The file is too large. The maximum file size is 5 MB.',
    ru: 'Файл слишком большой. Максимальный размер файла 5 Мб.',
  },
  fileTypeError: {
    eng: 'Invalid file format. Only TXT files are supported.',
    ru: 'Недопустимый формат файла. Поддерживаются только TXT-файлы.',
  },
  fileSymbolsError: {
    eng: 'File names can only contain letters, numbers, and underscores',
    ru: 'В названии файла разрешены только буквы, цифры и нижние подчеркивания',
  },
};
